@import "../node_modules/bootstrap/dist//css//bootstrap.min.css";
@import "../node_modules/font-awesome/css/font-awesome.min.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@font-face {
  font-family: "AvenirNext";
  src: url("./assets/fonts/avenir/avenirnextltpro-regular.eot");
  src: url("./assets/fonts/avenir/avenirnextltpro-regular.eot")
      format("embedded-opentype"),
    url("./assets/fonts/avenir/avenirnextltpro-regular.woff") format("woff"),
    url("./assets/fonts/avenir/avenirnextltpro-regular.ttf") format("truetype"),
    url("./assets/fonts/avenir/avenirnextltpro-regular.svg#AvenirNextLTProRegular")
      format("svg");
}

@font-face {
  font-family: "AvenirNextBold";
  src: url("./assets/fonts/avenir/avenirnextltpro-bold.eot");
  src: url("./assets/fonts/avenir/avenirnextltpro-bold.eot")
      format("embedded-opentype"),
    url("./assets/fonts/avenir/avenirnextltpro-bold.woff") format("woff"),
    url("./assets/fonts/avenir/avenirnextltpro-bold.ttf") format("truetype"),
    url("./assets/fonts/avenir/avenirnextltpro-bold.svg#AvenirNextLTProBold")
      format("svg");
}
body {
  font-family: "AvenirNext", sans-serif;
  color: #6f6f6f;
}
/* @font-face {
  font-family: "avenirnextltprobold";
  src: url("./assets/fonts/avenirnextltprobold.eot");
  src: url("./assets/fonts/avenirnextltprobold.eot") format("embedded-opentype"),
    url("./assets/fonts/avenirnextltprobold.woff2") format("woff2"),
    url("./assets/fonts/avenirnextltprobold.woff") format("woff"),
    url("./assets/fonts/avenirnextltprobold.ttf") format("truetype"),
    url("./assets/fonts/avenirnextltprobold.svg#avenirnextltprobold")
      format("svg");
}

@font-face {
  font-family: "avenirnextltproregular";
  src: url("./assets/fonts/avenirnextltproregular.eot");
  src: url("./assets/fonts/avenirnextltproregular.eot")
      format("embedded-opentype"),
    url("./assets/fonts/avenirnextltproregular.woff2") format("woff2"),
    url("./assets/fonts/avenirnextltproregular.woff") format("woff"),
    url("./assets/fonts/avenirnextltproregular.ttf") format("truetype"),
    url("./assets/fonts/avenirnextltproregular.svg#avenirnextltproregular")
      format("svg");
} */

.slick-next {
  right: 1px;
}
.slick-prev {
  left: 1px;
}
/*  */
.slides .slick-slide {
  height: 770px;
}
.header {
  display: block;
  padding-top: 1.5em;
  max-width: 300px;
  margin: 0em auto 0 auto;
  position: relative;
  z-index: 10;
}
.l-page-body-headless {
  border: 0 none;
}
.l-page-body {
  width: 74.64789%;
  float: left;
  margin-right: 1.40845%;
  display: inline;
  margin-right: 13.38028%;
  margin-left: 13.38028%;
  padding-bottom: 2em;
  color: #fff;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="submit"],
textarea {
  border: none;
  color: white;
  background-color: #9f9f9f;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="submit"],
select,
.customSelect,
textarea {
  outline: none;
  padding: 0.75em 0.5em;
  /* margin: 0 0 0.75em 0; */
  font-family: "AvenirNext", sans-serif;
  width: 100%;
  font-size: inherit;
}
.form-group {
  margin: 0 0 0.75em 0;
}
.linkcolor {
  color: #f7931e !important;
}
a:hover {
  color: #f47a00;
}
.help-block {
  color: #cf340a;
}
.tabs-tab-right-white {
  position: fixed;
  top: 50%;
  margin-top: -45px;
  z-index: 1000;
}
.tabs-tab-left-white {
  position: fixed;
  top: 50%;
  margin-top: -45px;
  z-index: 1000;
}
.tabs-tab-left-white {
  background-position: 0 -304px !important;
  height: 90px;
  width: 31px;
}
.tabs-tab-left-white {
  background: url("./assets/images/tabs-s8cf657c638.png") no-repeat;
}

element.style {
  display: block;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: #fff;
  outline: none;
  background: url("./assets/images/tabs-s8cf657c638.png") no-repeat;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  /* opacity: .75; */
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("./assets/images/icons-s58cb19cb71.png") no-repeat;
}
.slick-prev:before {
  content: "";
  background-position: 0 -60px;
  height: 20px;
  width: 21px;
  display: block;
  cursor: pointer;
  position: relative;
  margin: 0 auto;
  top: 50%;
  margin-top: -10px;
}
.slick-next:before {
  content: "";
  background-position: 0 -40px;
  height: 20px;
  width: 21px;
  display: block;
  cursor: pointer;
  position: relative;
  margin: 0 auto;
  top: 50%;
  margin-top: -10px;
}
.tabs-tab-right-white {
  background: url("./assets/images/tabs-s8cf657c638.png") no-repeat;
}
.tabs-tab-right-white {
  position: fixed;
  top: 50%;
  margin-top: -45px;
  z-index: 1000;
}

.tabs-tab-right-white {
  background-position: 0 -394px !important;
  height: 90px;
  width: 31px;
}
.slick-arrow.slick-prev.slick-disabled.tab.tabs-tab-left-white {
  display: none !important;
}
.slick-arrow.slick-next.slick-disabled.tab.tabs-tab-right-white {
  display: none !important;
}
.slick-slide.slick-active.slick-current.nextslideanim {
  height: 100%;
}
